@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap');

html {
    width: 100%;
    height: 100%;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#root {
  height: 100%;
}

body {
    font-family: "Manrope", sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
}

.loading {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 2px solid rgba(#145388, 0.2);
    border-radius: 50%;
    border-top-color: #145388;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    left: calc(45%);
    top: calc(45%);
    position: fixed;
    z-index: 1;
}

.navbar-container{
  position: fixed;
  top: 0;
  z-index: 10000;
  background: white;
  width: 100%;

  img{
    height: 60px;
  }

  .title{
    padding-left: 10px;
    font-family: "Manrope", sans-serif;
    font-weight: 600;
  };

  .sub-title{
    padding-left: 10px;
    font-family: "Manrope", sans-serif;
    font-weight: 200;
    color: #A8A8A8;
    font-size: 10px;
  }
}

.search-container{
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  position: fixed;
  top: 75px;
  z-index: 10000;
  width: 100%;
  background: white;
  animation: fadeIn 0.3s linear;

  &.scroll{
    display: none;
  }

  .search-form-group{
    position: relative;
    margin-bottom: 0 !important;
  }

  .first-icon{
    font-size: 25px;
    color: #A8A8A8;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .second-icon{
    font-size: 25px;
    color: #A8A8A8;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  input{
    border: transparent;
    background: #EFEFEF;
    padding-left: 50px;
    height: 45px;

    &:focus{
      background: #EFEFEF;
    }
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #CFCFCF;
      opacity: 1; /* Firefox */
      font-family: "Manrope", sans-serif;
      font-weight: 400;
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #CFCFCF;
      font-family: "Manrope", sans-serif;
      font-weight: 400;
    }
    
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #CFCFCF;
      font-family: "Manrope", sans-serif;
      font-weight: 400;
    }

  }
}

.categories-container{
  padding-top: 150px;

  &.scroll{
    padding-top: 100px;
  }

  .header-title{
    font-size: 12px;
    font-family: "Manrope", sans-serif;
    font-weight: 600;
  }

  .header-count{
    font-size: 12px;
    font-family: "Manrope", sans-serif;
    font-weight: 600;
  }

  .categories-content{
    display: flex;
    overflow-x: scroll;
    width: 100%;
    text-align: center;

    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  .card-categories{
    flex-shrink: 0;
    width: 100px;
    height: 105px;
    margin-right: 10px;
    padding-bottom: 15px;
    cursor: pointer;

    .img{
      border-radius: 15px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      width: 100%;
      height: 100%;
    }

    &.active{
      opacity: 1;
      .title{
        text-decoration: underline;
      }
    }
    &.inactive{
      opacity: 0.6;
    }

    .title{
      font-size: 11px;
      font-family: "Manrope", sans-serif;
      font-weight: 500;
    }
  }
}

.subcategories-container{

  .header-title{
    font-size: 12px;
    font-family: "Manrope", sans-serif;
    font-weight: 600;
    text-decoration: underline;
  }

  .subcategories-content{
    display: flex;
    overflow-x: scroll;
    width: 100%;
    text-align: left;

    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  .card-subcategories{
    flex-shrink: 0;
    // width: 50px;
    height: 20px;
    margin-right: 10px;
    padding-bottom: 15px;
    cursor: pointer;

    &.active{
      opacity: 1;
      .title{
        text-decoration: underline;
      }
    }
    &.inactive{
      opacity: 0.6;
    }

    .title{
      font-size: 12px;
      font-family: "Manrope", sans-serif;
      font-weight: 500;
    }
  }
}

.products-container{
  min-height: 250px;
  position: relative;
  

  .product-content{
    cursor: pointer;
    padding-bottom: 15px !important;
    border-bottom: 1px solid #f6f6f6;
    padding-top: 15px !important;
    position: relative;

    &:hover, &:active{
      background-color: #f6f6f6;
    }

    .icon-container{
      background: white;
      position: absolute;
      left: 5px;
      top: 12px;
      padding: 2px 5px;
      border-radius: 100%;

      &.sec-both{
        left: 25px;
      }
  
      i{
        font-size: 14px;
        color: #979797;
      }
  
      i.red{
        color: #EB6363;
      }
    }
  }

  .no-products{
    font-size: 12px;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    padding-top: 80px;
  }

  .loading{
    position: absolute;
    left: 45%;
    top: 25%;
  }

  img{
    border-radius: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    object-fit: cover;
    width: 100%;
    height: 110px;
  }

  .title{
    font-size: 12px;
    font-family: "Manrope", sans-serif;
    font-weight: 800;
  }

  .desc{
    font-size: 11px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    color: #777777;
    height: 60px;
  }

  .price-text{
    font-size: 11px;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
  }

  .price{
    font-size: 15px;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    text-align: right;

    i{
      font-size: 18px;
      position: relative;
      top: 2px;
    }
  }

  .icon{
    position: relative;
    top: 3px;
    font-size: 14px;
  }

  .tooltip-inner {
    border-radius: 14px;
  }

  .price-offer{
    font-size: 12px;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    color: #ADADAD;
    text-decoration: line-through;
    text-align: right;

    i{
      font-size: 14px;
      position: relative;
      top: 2px;
    }

    .price{
      color: #000000;
      text-decoration: none;
    }
  }
}

.modal-product{

  .modal-content{
    border-radius: 15px;
  }

  .modal-header{
    padding: 0;
    display: block;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  img{
    width: 100%;
    height: 35vh;
    object-fit: cover;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .btn-close{
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .title{
    font-family: "Manrope", sans-serif;
    font-weight: 800;
  }

  .desc{
    color: #777777;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
  }

  .price-text{
    font-size: 11px;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
  }

  .price{
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: 800;
    text-align: right;

    i{
      font-size: 18px;
      position: relative;
      top: 2px;
    }
  }

  .price-offer{
    font-size: 12px;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    color: #ADADAD;
    text-decoration: line-through;
    text-align: right;

    i{
      font-size: 16px;
      position: relative;
      top: 2px;
    }

    .price{
      color: #000000;
      text-decoration: none;
      font-family: "Manrope", sans-serif;
      font-size: 16px;
      font-weight: 800;
    }
  }
}

.suggested-container{

  .header-title{
    font-size: 12px;
    font-family: "Manrope", sans-serif;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .suggested-content{
    display: flex;
    overflow-x: scroll;
    width: 100%;

    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  .card-suggested{
    flex-shrink: 0;
    width: 240px;
    height: 110px;
    margin-right: 10px;
    padding-bottom: 15px;
    cursor: pointer;
    position: relative;

    img{
      border-radius: 15px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      width: 100%;
      height: 100%;
    }

    .icon-container{
      background: white;
      position: absolute;
      left: -2px;
      top: -3px;
      padding: 2px 5px;
      border-radius: 100%;

      i{
        font-size: 14px;
        color: #979797;
      }

      i.red{
        color: #EB6363;
      }
    }

    .title{
      font-size: 11px;
      font-family: "Manrope", sans-serif;
      font-weight: 800;
    }

    .subtitle{
      font-size: 11px;
      font-family: "Manrope", sans-serif;
      font-weight: 500;
      text-decoration: underline;
      margin-top: 5px;
    }
    .price-text{
      font-size: 11px;
      font-family: "Manrope", sans-serif;
      font-weight: 500;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  
    .price{
      font-size: 15px;
      font-family: "Manrope", sans-serif;
      font-weight: 500;
      text-align: left;
  
      i{
        font-size: 18px;
        position: relative;
        top: 2px;
      }
    }
  
    .icon{
      position: relative;
      top: 3px;
      font-size: 14px;
    }
  
    .tooltip-inner {
      border-radius: 14px;
    }
  
    .price-offer{
      font-size: 12px;
      font-family: "Manrope", sans-serif;
      font-weight: 500;
      color: #ADADAD;
      text-decoration: line-through;
      text-align: left;
  
      i{
        font-size: 14px;
        position: relative;
        top: 2px;
      }
  
      .price{
        color: #000000;
        text-decoration: none;
      }
    }
  }
}

.footer-container{

  .title{
    font-family: "Manrope", sans-serif;
    font-size: 14px;
    font-weight: 600;
  }

  .icon{
    font-size: 35px;
    color: #000000;
  }

  .sing{
    span{
      font-family: "Manrope", sans-serif;
      font-weight: 800;
      color: #000000;
    }
    span:first-child{
      font-family: "Manrope", sans-serif;
      font-weight: 300;
    }
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed !important;
  height: 100% !important;
  z-index: 110000 !important;
}

/* General sidebar styles */
.bm-menu {
  background: #ffffff;
  padding: 0;
  font-size: 1.15em;

  .title-menu{
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    color: #000000;
    font-size: 15px;
  }

  .icon{
    font-size: 28px;
    color: #000000;
  }

  .menu-item-headers{
    font-family: "Manrope", sans-serif;
    font-weight: 300;
    font-size: 13px;
    color: #000000;
  }

  .payment-card{
    background: #FBD96D;
    border-radius: 14px;
    width: 80%;
    padding: 20px;
    line-height: 14px;
    display: flex;

    .payment-card-img{
      margin-right: 15px;
    }

    .payment-card-text{
      font-size: 12px;
      font-family: "Manrope", sans-serif;
      font-weight: 400;
      color: #B38800;
    }
  }

  .cash{
    background: #DFF8EA;
    border-radius: 14px;
    width: 80%;
    padding: 20px;
    line-height: 14px;
    display: flex;

    .cash-img{
      margin-right: 15px;
    }

    .cash-text{
      font-size: 12px;
      font-family: "Manrope", sans-serif;
      font-weight: 400;
      color: #30A764;
    }
  }

  .footer-menu{
    position: absolute;
    bottom: 5px;

    img{
      width: 96%;
    }

    .title{
      font-family: "Manrope", sans-serif;
      font-size: 14px;
      font-weight: 600;
      font-size: 12px;
    }
  
    .icon{
      font-size: 30px;
      color: #000000;
    }
  
    .sing{
      line-height: 14px;
      span{
        font-family: "Manrope", sans-serif;
        font-weight: 800;
        color: #000000;
        font-size: 12px;
      }
      span:first-child{
        font-family: "Manrope", sans-serif;
        font-weight: 300;
        font-size: 12px;
      }
    }
  }
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  z-index: 100000 !important;
}

.bm-cross-button{
  background: black;
  border-radius: 100%;
  width: 24px;
  height: 25px;
}

.bm-cross {
  background: #ffffff;
}


.modal-languages{

  .modal-content{
    border-radius: 15px;
  }

  .modal-header{
    font-family: "Manrope", sans-serif;
    font-weight: 800;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .title{
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    font-size: 15px;
  }

  .form-check-label{
    font-family: "Manrope", sans-serif;
    font-weight: 300;
    font-size: 14px;
  }

  .form-check-input:checked {
    background-color: #145388;
    border-color: #145388;
  }

  img{
    width: 30px;
  }

  .form-check-input{
    position: relative;
    top: 4px;
  }
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
